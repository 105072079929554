import request from '@/plugins/axios'

/** S 商城列表 **/
// 商城列表
export const apiShopLists = (params: any) => request.get('/shop.shop/lists', {params})

// 导入上传
export const apiShopImport = (params: any) => request.post('/shop.shop/import', params)

// 门店单点登录
export const apiShopSso = (params: any) => request.post('/shop.shop/shopSso', params)

// 收银台单点登录
export const apiCashdeskSso = (params: any) => request.post('/shop.shop/cashdeskSso', params)

// 添加商城
export const apiShopAdd = (params: any) => request.post('/shop.shop/add', params)

// 商城详情
export const apiShopDetail = (params: any) => request.get('/shop.shop/detail', {params})

// 编辑商城
export const apiShopEdit = (params: any) => request.post('/shop.shop/edit', params)

// 删除商城
export const apiShopFrozen = (params: any) => request.post('/shop.shop/frozen', params)

// 修改超级管理员
export const apiShopChangeSuperAdmin = (params: any) => request.post('/shop.shop/changeSuperAdmin', params)

// 修改商城状态
export const apiShopSwitchStatus = (params: any) => request.post('/shop.shop/switchStatus', params)

// 历史套餐
export const apisetMealLogLists = (params: any) => request.get('/shop.shop/setMealLogLists', {params})

// 修改商城备注
export const apiChangeRemark = (params: any) => request.post('/shop.shop/changeRemark', params)

// 删除门店
export const apiShopDel = (params: any) => request.post('/shop.shop/del', params)
/** E 商城列表 **/


/** S 门店类型 **/
// 获取门店类型列表
export const apiShopTypeLists = (params: any) => request.get('/shop.shop_type/lists', {params})

// 获取门店类型添加
export const apiShopTypeAdd = (params: any) => request.post('/shop.shop_type/add', params)

// 获取门店类型编辑
export const apiShopTypeEdit = (params: any) => request.post('/shop.shop_type/edit', params)

// 获取门店类型删除
export const apiShopTypeDel = (params: any) => request.post('/shop.shop_type/del', params)

// 获取门店类型详情
export const apiShopTypeDetail = (params: any) => request.get('/shop.shop_type/detail', {params})

// 腾讯地图区域搜索
export const apiShopRegionSearch = (params: any) => request.get('/shop.shop/regionSearch', {params})

// 腾讯地图坐标转换搜索
export const apiShopGeocoderSearch = (params: any) => request.get('/shop.shop/geocoder', {params})

/** E 门店类型 **/


/** S 合作商 **/
// 获取合作商列表
export const apiPartnerLists = (params: any) => request.get('/shop.shop_type/lists', {params})

// 获取合作商添加
export const apiPartnerAdd = (params: any) => request.post('/shop.shop_type/add', params)

// 获取合作商编辑
export const apiPartnerEdit = (params: any) => request.post('/shop.shop_type/edit', params)

// 获取合作商删除
export const apiPartnerDel = (params: any) => request.post('/shop.shop_type/del', params)

// 获取合作商详情
export const apiPartnerDetail = (params: any) => request.get('/shop.shop_type/detail', {params})

/** E 合作商 **/


/** S 套餐列表 **/
// 套餐列表
export const apiSetMealLists = (params: any) => request.get('/shop.set_meal/lists', {params})

// 添加套餐
export const apiSetMealAdd = (params: any) => request.post('/shop.set_meal/add', params)

// 套餐详情
export const apiSetMealDetail = (params: any) => request.get('/shop.set_meal/detail', {params})

// 编辑套餐
export const apiSetMealEdit = (params: any) => request.post('/shop.set_meal/edit', params)

// 删除套餐
export const apiSetMealDelete = (params: any) => request.post('/shop.set_meal/del', params)

// 修改套餐状态
export const apiSetMealSwitchStatus = (params: any) => request.post('/shop.set_meal/status', params)

/** E 套餐列表 **/



/** S 门店坐标列表 **/

// 门店坐标列表
export const apiShopCoordinateLists = () => request.get('/shop.shop/coordinateLists')

/** E 门店坐标列表 **/